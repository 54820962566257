<template>
  <div class="window-body">
    <BlockUI
      id="loading"
      :message="$t('loading')"
      v-if="loading"
      html="<i class='fa fa-cog fa-spin fa-2x fa-fw'></i>"
    />
    <CRow class="height_100">
      <CCol xl="7" lg="12" v-bind:class="{customHide:fullScreenMap}" style="padding:0">
        <!-- <Filters /> -->
        <Trucks
        @fullScreenMap="toggleFullScreenMap"
        @truck-filter="handleTruckPagination"
        @openedDetails="toggleOpenedDetails"
        @getDropdownValues="getDropdownValues"
        @updateCurrentPage="updateCurrentPage"
        @updateTotalPages="updateTotalPages"
        @loading="handleLoading"
        @clear-filters="clearFilters"
        :totalPages="totalPages"
        :currentPage="currentPage"
        :deliveryFilters="deliveryFilters"
        :contTypeSelect="contTypeSelect"
        :iutSelect="iutSelect"
        :vehicleTypeSelect="vehicleTypeSelect"
        :materialCategorySelect="materialCategorySelect"
        />
      </CCol>
      <CCol :xl="fullScreenMap ? '12' : '5'" lg="12" v-bind:class="{smooth:fullScreenMap}" style="padding:0">
        <Map :mode="'external'" class="height_100" :fullScreenMap="fullScreenMap && !openedDetails" @hideFullScreen="hideFullScreen" />
      </CCol>
    </CRow>
    <CRow >
      <!-- <a href="javascript:void" @click="test">asdasdsad</a> -->
    </CRow>
    <CToaster :autohide="3000" class="main-toast">
      <CToast :key="'toast'" :show="toastShow" :color="toastColor">
        {{ toastMessage }}
      </CToast>
    </CToaster>
  </div>
</template>

<script>
import Map from "../components/Map.vue";
import Filters from "../components/Filters.vue";
import Trucks from "../components/Trucks.vue";
import * as Socket from "../plugins/socket_client";
import { store } from "../store/store";
import Vue from "vue"
export default {
  name: "ExtDashboard",
  components: {
    Map,
    Filters,
    Trucks,
  },
  computed: {
    selectedPlants() {
      return store.state.selectedPlants;
    }
  },
  data() {
    return {
      loading: true,
      deliveryFilters : {
        truck_plate:"",
        dn_number:"",
        status_date:[],
        plant: "",
        delivery_address: "",
        sold_to_name: "",
        carrier: "",
        vehicle_type: [],
        haulier_type: [],
        iut: [],
        material_type: [],
        deliveryStatus: [],
        
      },
      // props toast
      toastShow: false,
      toastColor: "",
      toastMessage: "",
      fullScreenMap:false,
      openedDetails: false,
      totalPages: 0,
      currentPage: 1,
      contTypeSelect: [],
      iutSelect: [],
      materialCategorySelect: [],
      vehicleTypeSelect: []

      
    };
    
  },
  created() {
    Socket.connect();
  },
  mounted() {
   this.loading = false;
    //this.processQueryFilters(this.$route.query)
    if (this.selectedPlants.length == 0 && this.$store.state.selectedPlant != null) {
      this.selectedPlants = [...this.selectedPlants, this.$store.state.selectedPlant]
    }
    let userFilters = JSON.parse(sessionStorage.getItem('userFilters'));
    let userSelectedPlants = JSON.parse(sessionStorage.getItem('userSelectedPlants'));
    if (userFilters) {
      this.deliveryFilters = userFilters;
      delete this.deliveryFilters.position_timestamp;
    }
    if (userSelectedPlants) {
      store.commit('setSelectedPlants', userSelectedPlants);
    }
    
  //  this.getDropdownValues()
   window.history.replaceState(null, null, window. location.toString().split("?")[0])
  },
  methods: {
    toggleFullScreenMap(value){
      this.fullScreenMap = value
        window.dispatchEvent(new Event('resize'));
      this.sleep(100).then(() => {
        window.dispatchEvent(new Event('resize'));
      });
      
    },
    clearFilters() {
      this.deliveryFilters = {truck_plate:"", dn_number:"", status_date:[], plant: "", delivery_address: "", sold_to_name: "", carrier: "", vehicle_type: [], haulier_type: [], iut: [], material_type: [], deliveryStatus: []}
      sessionStorage.setItem('userFilters',JSON.stringify({truck_plate:"", dn_number:"", status_date:[], plant: "", delivery_address: "", sold_to_name: "", carrier: "", vehicle_type: [], haulier_type: [], iut: [], material_type: [], deliveryStatus: []}))
    },
    getDropdownValues() {
      Vue.prototype.$http.get('/api/delivery/truck/dropdown').then(res => {
        let data = res.data;
        this.contTypeSelect = data.haulierTypes
        this.materialCategorySelect = data.materialTypes;
        this.vehicleTypeSelect = data.vehicleTypes;
        this.iutSelect = data.iutTypes;
      })
    },
    toggleOpenedDetails(value){
      this.openedDetails = value
    },
    hideFullScreen(val) {
      this.fullScreenMap = val;
      window.dispatchEvent(new Event('resize'));
      this.sleep(100).then(() => {
        window.dispatchEvent(new Event('resize'));
      });
    },
    showToastError(msg) {
      const self = this;
      self.toastShow = true;
      self.toastColor = "danger";
      self.toastMessage = msg;

      self.sleep(3000).then(() => {
        self.toastShow = false;
      });
    },
    updateCurrentPage(value) {
      this.currentPage = value;
    },
    
    updateTotalPages(value) {
            this.totalPages = value;
      },
    async handleTruckPagination(filter) {
        this.loading = true;
        this.currentPage = filter.currentPage;
        await Vue.prototype.$http.post('/api/delivery/truck/' + filter.perPage + '/' + filter.currentPage, {
          isActive: store.state.filters.showInactive,
          filters: this.deliveryFilters,
          plant: this.selectedPlants
        }).then(res => {
            store.commit('setTrucksDn', res.data)
            store.commit('checkTrucksPlant', this.selectedPlants)
            this.totalPages = Math.ceil(store.state.totalTrucks / filter.perPage) 
          if (res.data.length == 0) {
            this.totalPages = store.state.truckActivePages;
            this.currentPage = 1;
            // this.handleTruckPagination({currentPage: this.currentPage, perPage: 10})
          }
            this.loading = false;
        })
      },
      handleLoading(val) {
        this.loading = val;
      },  
      showToastInfo(msg) {
        const self = this;
        self.toastShow = true;
        self.toastColor = "success";
        self.toastMessage = msg;

        self.sleep(3000).then(() => {
          self.toastShow = false;
        });
      },
      processQueryFilters(query) {
        for (var key of Object.keys(query)) {
          var values = query[key].split(",");
          for (var value of values) {
            this.addFilter(value, key);
        }
      }
    },
    addFilter(data, type) {
      store.commit("addFilter", { type: type, value: data });
      Socket.add(type, data);
    },
  },
};
</script>

<style lang="scss" >
.main-toast {
  z-index: 11000;
}

.slide-fade-enter-active {
  transition: all 0.4s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.window-body {
  padding: 0;
  height: 100vh;
  overflow: hidden;
}
.map_parent {
  height: 100% !important;
  max-height: 100% !important;
}
.height_100 {
  height: 100%;
}
.customHide{
  position: absolute;
height: 0;
width: 0;
display: block;
}
.smooth{
  // transition: all 0.2s ease;
  animation: smooth 0.3s 1;
}
@keyframes smooth {
  0%   {opacity: 1;}
  10%   {opacity: 0;}
  50%  {opacity: 0;}
  100% {opacity: 1;}
}
</style>